<template>
  <div id="questions">
    <v-container class="my-4">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
              />
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click.stop="
                  () => {
                    dialogContent = 'Add';
                    dialog = true;
                  }
                "
              >
                <v-icon class="mr-2">add_circle</v-icon>
                <span>Soru Ekle</span>
              </v-btn>
              <v-btn icon @click="getItems">
                <v-icon class="mr-2">refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Yükleniyor..."
            >
              <template v-slot:[`item.q`]="{ item }">
                <div class="question" v-html="item.q"></div>
              </template>
              <template v-slot:[`item.lessonName`]="{ item }">
                {{ item.rowNumber }}:
                {{ item.lessonName }}
              </template>
              <template v-slot:[`item.disabled`]="{ item }">
                <div class="pl-4">
                  <v-badge dot :color="item.disabled ? 'error' : 'success'" />
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu close-on-content-click>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon>
                        <v-icon>create</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Düzenle</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Sil</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="600" scrollable>
      <component
        :is="dialogContent"
        @close="dialog = false"
        v-bind="selectedItemProps"
        @refresh="getItems"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.enabled"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn icon @click="snackbar.enabled = false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Confirm from "@/components/Confirm.vue";

export default {
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Soru",
          align: "start",
          filterable: true,
          value: "q"
        },
        { text: "Ders", value: "courseName" },
        { text: "Modül", value: "lessonName" },
        { text: "Durum", value: "disabled", width: "90px" },
        { text: "Oluşturulma", value: "creationTime", width: "160px" },
        // { text: "Güncellenme", value: "updateTime", width: "160px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [],
      selectedItem: {},
      dialog: false,
      dialogContent: "",
      snackbar: {
        enabled: false,
        text: "",
        color: "info",
        timeout: 3000
      },
      courses: [],
      lessons: []
    };
  },
  computed: {
    selectedItemProps() {
      if (this.dialogContent === "Add") {
        return {
          courses: this.courses
        };
      } else if (this.dialogContent === "Edit") {
        return {
          courses: this.courses,
          question: this.selectedItem
        };
      } else {
        return {};
      }
    }
  },
  methods: {
    async getItems() {
      this.loading = true;

      try {
        this.items = [];

        // Get all quesitons
        const query = await firebase
          .firestore()
          .collection("questions")
          .orderBy("q")
          .get();

        query.forEach(async doc => {
          if (doc.exists) {
            const question = doc.data();
            question.id = doc.id;
            question.updateTime =
              question.updateTime != null
                ? new Date(question.updateTime.seconds * 1000).toLocaleString(
                    "tr-TR"
                  )
                : question.updateTime;
            question.creationTime =
              question.creationTime != null
                ? new Date(question.creationTime.seconds * 1000).toLocaleString(
                    "tr-TR"
                  )
                : question.creationTime;

            // Get course name
            const course = await firebase
              .firestore()
              .doc(`/courses/${question.courseId}`)
              .get();

            if (course.exists) {
              question.courseName = course.data().name;
            }

            // Get lesson name
            const lesson = await firebase
              .firestore()
              .doc(`/lessons/${question.lessonId}`)
              .get();

            if (lesson.exists) {
              question.lessonName = lesson.data().title;
              question.rowNumber = lesson.data().rowNumber;
            }

            this.items.push(question);
          }
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.snackbar = {
          enabled: true,
          color: "error",
          text:
            "Soru listesi alınamadı. " +
            err.code +
            " " +
            err.message +
            " " +
            err.details
        };
      }
    },
    openItem(item) {
      this.$router.push({
        name: "question-dashboard",
        params: {
          id: item.id
        }
      });
    },
    async deleteItem(item) {
      const confirmation = await this.$refs.confirm.open(
        "Soru Silme",
        "Bu işlem geri alınamaz. Devam etmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.snackbar = {
          enabled: true,
          color: "info",
          text: "Soru siliniyor. Lütfen bekleyin...",
          timeout: 0
        };

        try {
          // Soruyu sil
          await firebase
            .firestore()
            .collection("questions")
            .doc(item.id)
            .delete();

          setTimeout(() => {
            this.getItems();

            // Clear snackbar
            this.snackbar = {
              enabled: false,
              color: "info",
              text: "",
              timeout: 3000
            };
          }, 1000);

          this.snackbar = {
            enabled: true,
            color: "success",
            text: "Soru silindi.",
            timeout: 0
          };
        } catch (err) {
          this.snackbar = {
            enabled: true,
            color: "error",
            text: err,
            timeout: 3000
          };
        }
      }
    },
    async editItem(item) {
      this.snackbar = {
        enabled: true,
        color: "info",
        text: "Soru alınıyor...",
        timeout: 0
      };

      try {
        // Get question
        const qsQuestions = await firebase
          .firestore()
          .collection("questions")
          .doc(item.id)
          .get();

        if (qsQuestions.exists) {
          this.selectedItem = qsQuestions.data();
          this.selectedItem.id = qsQuestions.id;

          // Hide snackbar
          this.snackbar.enabled = false;

          // Open dialog
          this.dialogContent = "Edit";
          this.dialog = true;
        } else {
          throw "Soru bulunumadı.";
        }
      } catch (error) {
        // Show error
        this.snackbar = {
          enabled: true,
          color: "error",
          text: `Soru alınamadı: ${error.code} ${error.message} ${error.details}`,
          timeout: 3000
        };
      }
    },
    openLessons(item) {
      this.$router.push({
        name: "lessons",
        params: {
          id: item.id
        }
      });
    },
    async getAllCourses() {
      this.courses = [];

      try {
        const qs = await firebase
          .firestore()
          .collection("courses")
          .orderBy("name")
          .get();
        qs.forEach(doc => {
          this.courses.push({
            id: doc.id,
            name: doc.data().name
          });
        });
      } catch (error) {
        // Show error
        this.snackbar = {
          enabled: true,
          color: "error",
          text: `Eğitimler alınamadı: ${error.code} ${error.message} ${error.details}`,
          timeout: 3000
        };
      }
    }
  },
  mounted() {
    this.getItems();
    this.getAllCourses();
  },
  components: {
    Add,
    Edit,
    Confirm
  }
};
</script>

<style lang="scss">
#adminQuestions {
  .question {
    * {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }
}
</style>
