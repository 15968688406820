<template>
  <div id="editQuestion">
    <v-card>
      <v-form ref="form" @submit.prevent="update()">
        <v-card-title>Yeni Soru</v-card-title>
        <v-divider />
        <v-card-text>
          <div>
            <v-checkbox
              v-model="question.disabled"
              label="Yayından kaldırılsın mı?"
            />
          </div>
          <v-autocomplete
            :items="courses"
            :filter="courseFilter"
            item-text="name"
            item-value="id"
            label="Eğitim"
            v-model="question.courseId"
          ></v-autocomplete>
          <v-autocomplete
            :items="lessons"
            :filter="lessonFilter"
            item-text="title"
            item-value="id"
            label="Modül"
            v-model="question.lessonId"
          ></v-autocomplete>
          <v-select
            class="mt-5"
            :items="['A', 'B', 'C', 'D', 'E']"
            label="Doğru Yanıt"
            v-model="question.correct"
            :rules="correctRules"
          ></v-select>
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">Soru</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="question.q"
            />
          </div>
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">A Şıkkı</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="question.a"
            />
          </div>
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">B Şıkkı</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="question.b"
            />
          </div>
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">C Şıkkı</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="question.c"
            />
          </div>
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">D Şıkkı</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="question.d"
            />
          </div>
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">E Şıkkı</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="question.e"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Güncelle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar
      v-model="snackbar.enabled"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn icon @click="snackbar.enabled = false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

export default {
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    courses: {
      type: Array,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    lessons: [],
    submitDisabled: false,
    snackbar: {
      enabled: false,
      text: "",
      color: "success",
      timeout: 2000
    },
    editor: ClassicEditor,
    editorConfig: {
      language: "tr",
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo"
      ]
    }
  }),
  computed: {
    correctRules() {
      return [v => !!v || "Lütfen doğru yanıtı seçin"];
    }
  },
  watch: {
    "question.courseId": async function(val) {
      if (val) {
        await this.getLessons(val);
      }
    }
  },
  methods: {
    courseFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    lessonFilter(item, queryText) {
      const textOne = item.title.toLowerCase();
      const textTwo = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.question.q = "";
      this.question.a = "";
      this.question.b = "";
      this.question.c = "";
      this.question.d = "";
      this.question.e = "";
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async update() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.snackbar = {
          enabled: true,
          color: "info",
          text: "Soru güncelleniyor. Lütfen bekleyin...",
          timeout: 0
        };

        try {
          const now = new Date(Date.now());
          const newQuestion = this.question;
          newQuestion.updateTime = now;

          // Update question to database
          await firebase
            .firestore()
            .collection("questions")
            .doc(this.question.id)
            .update(newQuestion);

          setTimeout(() => {
            this.submitDisabled = false;
            this.snackbar.enabled = false;
            this.refresh();
            this.close();
          }, 1000);

          this.snackbar = {
            enabled: true,
            color: "success",
            text: "Soru başarıyla güncellendi.",
            timeout: 3000
          };
        } catch (err) {
          this.submitDisabled = false;

          this.snackbar = {
            enabled: true,
            color: "error",
            text:
              "Soru güncellenemedi. " +
              err.code +
              " " +
              err.message +
              " " +
              err.details
          };
        }
      }
    },
    async getLessons(courseId) {
      this.lessons = [];
      try {
        const qs = await firebase
          .firestore()
          .collection("lessons")
          .where("courseId", "==", courseId)
          .orderBy("rowNumber")
          .get();
        qs.forEach(doc => {
          this.lessons.push({
            id: doc.id,
            title: `Modül ${doc.data().rowNumber}: ${doc.data().title}`
          });
        });
      } catch (error) {
        throw `Modüller alınamadı: ${error.code} ${error.message} ${error.details}`;
      }
    }
  },
  async mounted() {
    await this.getLessons(this.question.courseId);
  }
};
</script>

<style lang="scss">
#editQuestion {
  overflow-x: hidden;

  .ck-content {
    min-height: 100px;
  }
}
</style>
